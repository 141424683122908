.pb_card {
  position: relative;
  background: white;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 24px 0px;
  border-radius: 0.75rem;

  margin-bottom: 1rem;
  overflow: hidden;
}

.pb_card {
  
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 24px 0px;

  

  transition: transform 0.3s ease-in-out;
  transform: scale(1);
}

.pb_card::after {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  border-radius: 5px;
  box-shadow: rgba(2, 7, 0, 0.12) 0px 0px 24px 0px;
  transition: opacity 0.3s ease-in-out;

}

.pb_card:hover::after {
  opacity: 0.6;
}

.pb_card:hover,
.pb_card:active {
  transform: scale(1.01);
  cursor: pointer;
}