// Override default variables before the import

// Import Bootstrap and its default variables
@import "~bootstrap/scss/bootstrap.scss";

img {
  width: 100%;
  height: auto;
  display: block;
  object-fit: cover;
}

ul {
  padding: 0;
  margin: 0;
}

a {
  text-decoration: none;
}

p {
  margin-bottom: 0.5rem;
}

.sticky {
  position: sticky;
  top: 72px;
}
