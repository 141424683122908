.pb_splash {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  div {
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}