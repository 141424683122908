.pb_navigation {
  box-shadow: rgba(0, 0, 0, 0.12) 0px 0px 24px 0px;
  background: white;

  input {
    border-radius: 20px;
    padding-right: 18px;
    padding-left: 18px;
  }
}
